import Helpers from '@/utilities/Helpers';

export function required (value) {
    return !!value || 'Campo Obrigatório.';
}

export function validEmail (value) {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'E-mail inválido!';
}

export function validPassword (value) {
    const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
    return pattern.test(value) || 'Senha deve conter pelo menos 8 caracteres, 1 letra maiúscula e 1 letra minúscula';
}

export function validCpf (value) {

    if (value) {
        const cpf = value.replace(/[^\d]+/g, '');
        return Helpers.methods.isCpf(cpf) || 'Cpf Inválido'
    }

    return 'Cpf Inválido';
}

export function validCnpj (value) {

    if (value) {
        
        const cnpj = value.replace(/[^\d]+/g, '');
        return Helpers.methods.isCnpj(cnpj) || 'Cnpj Inválido'
    }

    return 'Cnpj Inválido';
}